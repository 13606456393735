import { Notification, useToaster } from "rsuite";

const printHeaders = ({ status, statusText }) => {
  const h = {
    422: "Не может быть выполнено",
  };
  return h[status] || `${status} ${statusText}`;
}

const usePopup = () => {
  const toaster = useToaster();
  // https://rsuitejs.com/components/notification/#code-lt-notification-gt-code

  const messageToaster = ({
    message = "Выполнено",
    header,
    type = "success",
    closable = true,
    placement = "topCenter",
    duration = 5000,
    onClose,
  }) => {
    return toaster.push(
      <Notification
        className="border-highlight"
        type={type}
        header={header || message}
        closable={closable}
        duration={duration}
        onClose={onClose}
      >
        {header ? message : null}
      </Notification>, {
        placement,
      }
    );
  };

  const responseToaster = async res => {
    let message = <Notification duration={5000} closable type="success" header="Запрос успешно выполнен" />;
    if (!res.ok) {
      let msg = null;
      try { msg = await res.json() } catch (e) { console.log(e.toString()) }
      message = (
        <Notification
          duration={5000}
          closable
          type="error"
          header={printHeaders(res)}
        >
          {msg?.message?.ru}
        </Notification>
      );
    }

    toaster.push(
      message,
      { placement: "topCenter" }
    );

    // No Content
    if (res.status === 204) return res;    
  };

  const removeToaster = (key) => toaster.remove(key);

  const clearToaster = () => toaster.clear();

  return {
    responseToaster,
    messageToaster,
    removeToaster,
    clearToaster,
  };
};

export default usePopup;
