import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CheckTreePicker } from "rsuite";

import { loadingStatus } from "helpers/fetcher";
import { cloneDeep } from "lodash";
import { listToTree } from "helpers/utils";

const CheckTreeComponent = ({ onQueryString }) => {
  const [treeData, setTreeData] = useState([]);
  const [filterCats, setFilterCats] = useState([]);
  const categories = useSelector(state => state.deviceCategory);

  const builder = (data, hub = []) => {
    data.forEach(({ id, children = [], ...rest }) => {
      hub.push(id);
      if (children.length) builder(children, hub);
    })
    return hub;
  };

  const handleSelect = (item, values, event) => {
    console.log(item)
    const hub = [];
    let uniqueCats = [];
    builder([item], hub);
    if (event.target.checked) {
      uniqueCats = Array.from(new Set([...filterCats, ...hub]));
    } else {
      uniqueCats = filterCats.filter(c => hub.findIndex(p => p === c) === -1);
    }
    setFilterCats(uniqueCats);
    onQueryString(p => {
      const objParams = new URLSearchParams(p);
      objParams.delete("category");
      const _cat = uniqueCats.length ? `&category=${uniqueCats.join(",")}` : "";
      return `${objParams.toString()}${_cat}`;
    });
  }

  useEffect(() => {
    if (categories.status === loadingStatus.SUCCEEDED) {
      const _clone = cloneDeep(categories.data);
      const _data = listToTree(_clone);
      setTreeData(_data);
    }
  }, [categories.status]);

  if (treeData.length === 0) return null;

  return (
    <CheckTreePicker
      cascade={false}
      value={filterCats}
      data={treeData}
      placeholder="Отобрать по категориям"
      style={{ width: "100%", marginBottom: 15 }}
      onSelect={handleSelect}
    />
  );
};

export default CheckTreeComponent;
