import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, InlineEdit, Loader, Tree } from "rsuite";
import { MdOutlineClose } from "react-icons/md";
import { cloneDeep } from "lodash";

import {
  createDeviceCategory,
  patchDeviceCategory,
  deleteDeviceCategory,
  fetchDeviceCategory,
} from "redux/slices/deviceCategorySlice";
import { loadingStatus } from "helpers/fetcher";
import { listToTree } from "helpers/utils";
import usePopup from "hooks/usePopup";
import user from "helpers/user";

const CategoriesTreeComponent = () => {
  const [treeData, setTreeData] = useState([]);
  const [create, setCreate] = useState({});

  const dispatch = useDispatch();
  const categories = useSelector(state => state.deviceCategory);
  const { responseToaster } = usePopup();

  const handleUpdateTree = (data) => {
    console.log(data)
    setTreeData(data);
  };

  const handleSetNode = (node, e) => {
    if (e) e.stopPropagation();
    console.log(node);
    setCreate(node);
  };

  const handleCreate = async () => {
    // если есть parent_id то редактируем
    let res = null;
    if (create.value) {
      res = await patchDeviceCategory(create.value, { name: create.label });
    } else {
      if (create.path) {
        const parents = create.path.split(".");
        console.log(parents);
      }
      res = await createDeviceCategory({
        name: create.label,
        parent_id: create.parent_id || 0,
        organization_id: +user.profile.organization_id,
      });
    }
    responseToaster(res);
    dispatch(fetchDeviceCategory(`?organization_id=${user.profile.organization_id}`));
  };

  const handleDelete = async (node, e) => {
    e.stopPropagation();
    const res = await deleteDeviceCategory(node.value);
    responseToaster(res);
    dispatch(fetchDeviceCategory(`?organization_id=${user.profile.organization_id}`));
  };

  useEffect(() => {
    if (categories.status === loadingStatus.SUCCEEDED) {
      const _clone = cloneDeep(categories.data);
      const _data = listToTree(_clone, { label: "Новая папка" });
      // const _data = listToTree(_clone);
      setTreeData(_data);
    }
  }, [categories.status]);

  // if (treeData.length === 0) return null;

  return (
    <div style={{ height: "100%" }}>
      {categories.status === loadingStatus.LOADING && <Loader />}
      <InlineEdit
        size="sm"
        defaultValue="Новая папка"
        stateOnBlur="cancel"
        onEdit={e => handleSetNode({ label: "Новая папка", parent_id: 0 }, e)}
        onChange={(label) => handleSetNode({ label, parent_id: 0 })}
        onSave={handleCreate}
      />
      <Tree
        style={{ height: "100%", maxHeight: "100%" }}
        showIndentLine
        data={treeData}
        onSelect={() => console.log('не должно сработать')}
        draggable
        onDrop={({ createUpdateDataFunction }, event) => {
          handleUpdateTree(createUpdateDataFunction(treeData));
        }}
        // cascade={false}
        // value={value}
        // onChange={value => setValue(value)}
        renderTreeNode={(node) => {
          // if (!node.value) {
          //   return (
          //     <InlineEdit
          //       size="sm"
          //       defaultValue={node.label}
          //       stateOnBlur="cancel"
          //       onEdit={e => handleSetNode(node, e)}
          //       onChange={(label) => handleSetNode({ ...node, label })}
          //       onSave={handleCreate}
          //     />
          //   );
          // }
          return (
            <>
              <InlineEdit
                size="sm"
                defaultValue={node.label}
                stateOnBlur="cancel"
                onEdit={e => handleSetNode(node, e)}
                onChange={(label) => handleSetNode({ ...node, label })}
                onSave={handleCreate}
              />
              {node.value && (
                <IconButton
                  size="xs"
                  appearance="subtle"
                  icon={<MdOutlineClose color="red" onClick={e => handleDelete(node, e)} />}
                />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default CategoriesTreeComponent;
