import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  InputGroup,
  Button,
  FlexboxGrid,
  Schema,
  SelectPicker,
  TagPicker,
  Message,
  IconButton,
} from "rsuite";
import { MdClose, MdAdd } from "react-icons/md";

import InputFieldComponent from "components/forms/InputFieldComponent";
import HeaderComponent from "components/HeaderComponent";
import ErrorComponent from "components/ErrorComponent";
import CloseButtonComponent from "components/CloseButtonComponent";
import {
  fetchBrightness,
  fetchBrightnessPlayers,
  createBrightness,
  putBrightness,
} from "redux/slices/brightnessSlice";
import { brightnessFormInitialData } from "constants/form";

const SCHEDULE_COUNTER_STEP = 5;

const { StringType } = Schema.Types;
const model = Schema.Model({
  name: StringType().isRequired("Обязательное поле"),
});

const BrightnessSchedulesFormComponent = () => {
  const dispatch = useDispatch();
  const { playersTags } = useSelector(state => state.tags);
  const { players, formData } = useSelector(state => state.brightness);

  const formRef = useRef();
  const [formValue, setFormValue] = useState(brightnessFormInitialData);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [filteredTag, setFilteredTag] = useState();
  const [customErrors, setCustomErrors] = useState({ players: null, schedule: null });

  const handleSelectedPlayers = val => {
    setSelectedPlayers(val || []);
    setCustomErrors({ ...customErrors, players: null });
  };
  const handleChangeTime = (index, e) => {
    schedule[index].time = e.target.value;
    setSchedule([...schedule]);
    setCustomErrors({ ...customErrors, duplicate: null });
  };
  const handleSetValue = (index, e) => {
    schedule[index].value = parseInt(e.target.value);
    setSchedule([...schedule]);
  }
  const handleMinus = (index) => {
    schedule[index].value -= SCHEDULE_COUNTER_STEP;
    if (schedule[index].value < 0) schedule[index].value = 0;
    setSchedule([...schedule]);
  }
  const handlePlus = (index) => {
    schedule[index].value += SCHEDULE_COUNTER_STEP;
    if (schedule[index].value > 100) schedule[index].value = 100;
    setSchedule([...schedule]);
  }
  const handleAddScheduleItem = () => {
    setSchedule([...schedule, { time: "00:00", value: 0 }]);
    setCustomErrors({ ...customErrors, schedule: null });
  };
  const handleRemoveScheduleItem = index => {
    schedule.splice(index, 1);
    setSchedule([...schedule]);
    setCustomErrors({ ...customErrors, duplicate: null });
  };
  const handleSubmit = async () => {
    const errors = [];
    if (selectedPlayers.length === 0) errors.players = "Выберите устройства";
    if (schedule.length === 0) errors.schedule = "Добавьте расписание";
    const times = schedule.map(({ time }) => time);
    const isDuplicate = times.some((item, idx) => times.indexOf(item) !== idx);
    if (isDuplicate) errors.duplicate = "В расписании найдены дубли";
    setCustomErrors(errors);

    const { id, ...restForm } = formValue;

    if (formRef.current.check() && Object.values(errors).filter(err => err).length === 0) {
      restForm.players = selectedPlayers;
      restForm.schedule = schedule.map(s => ({ ...s, value: s.value / 100 }));
      if (id) await putBrightness(id, restForm);
      else await createBrightness(restForm);
      dispatch(fetchBrightness());
    }
  };

  useEffect(() => {
    const { players: formPlayers, schedule: formSchedule, ...rest } = formData;
    const existingPlayers = formPlayers.map(({ id }) => id);
    const existingSchedule = formSchedule.map(s => ({ ...s, value: s.value * 100 }));
    setFormValue(rest);
    setSchedule(existingSchedule);
    setSelectedPlayers(existingPlayers);
    if (!rest.id) {
      setSchedule([{ time: "00:00", value: 0 }]);
    }
  }, [formData]);

  useEffect(() => {
    const url = `?hide_children=1${filteredTag ? `&tag=${filteredTag}` : ""}`;
    dispatch(fetchBrightnessPlayers(url));
  }, [dispatch, filteredTag]);

  return (
    <Form
      model={model}
      ref={formRef}
      onChange={setFormValue}
      formValue={formValue}
      fluid
    >
      <HeaderComponent
        title={`${formValue.id ? "Изменить" : "Создать"} расписание`}
        tag="h4"
        gutter={0}
      />
      
      <HeaderComponent
        title="Введите данные"
        tag="h6"
        gutter={0}
      />
      <InputFieldComponent name="name" label="Название" />
      <InputFieldComponent name="description" label="Описание" />

      <HeaderComponent
        title="Выберите устройства"
        tag="h6"
        gutter={0}
      />
      {(playersTags.length > 0) ? (
        <Form.Group>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={5}>
              <Form.ControlLabel>Отобрать по тегу:</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={19}>
              <SelectPicker
                data={playersTags.map(({ id, name }) => ({ label: name, value: id }))}
                onChange={setFilteredTag}
                block
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form.Group>
      ) : (
        <Message type="warning">Ни один тег устройства не создан</Message>
      )}

      {(players.length > 0) ? (
        <Form.Group>
          <Form.ControlLabel>Доступные устройства</Form.ControlLabel>
          <TagPicker
            data={players.map(({ id, name }) => ({ label: name, value: id }))}
            onChange={handleSelectedPlayers}
            value={selectedPlayers}
            block
          />
          {customErrors.players ? <ErrorComponent message={customErrors.players} /> : ""}
        </Form.Group>
      ) : (
        <Message showIcon type="warning">Устройств не найдено</Message>
      )}

      <HeaderComponent
        title="Создайте расписание"
        tag="h6"
        gutter={0}
      />
      <Message showIcon type="warning">Начальное время должно быть в 00:00</Message>
      {customErrors.duplicate ? <ErrorComponent message={customErrors.duplicate} /> : ""}
      {schedule.map((s, index) => (
        <Form.Group key={`${s.time}${s.value}${index}`}>
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item colspan={9}>
              <input
                type="time"
                className="rs-input"
                defaultValue={schedule[index].time}
                onBlur={e => handleChangeTime(index, e)}
                readOnly={index === 0}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={9}>
              <InputGroup>
                <InputGroup.Button onClick={() => handleMinus(index)}>
                  -
                </InputGroup.Button>
                <input
                  type="number"
                  className="rs-input"
                  defaultValue={schedule[index].value}
                  onBlur={e => handleSetValue(index, e)}
                  min={0}
                  max={100}
                />
                <InputGroup.Button onClick={() => handlePlus(index)}>
                  +
                </InputGroup.Button>
              </InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3}>
              {index !== 0 && (
                <IconButton
                  icon={<MdClose />}
                  onClick={() => handleRemoveScheduleItem(index)}
                />
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form.Group>
      ))}

      <Form.Group>
        <IconButton
          icon={<MdAdd />}
          onClick={handleAddScheduleItem}
          appearance="link"
        >
          Добавить время
        </IconButton>
        {customErrors.schedule ? <ErrorComponent message={customErrors.schedule} /> : ""}
      </Form.Group>

      <Form.Group>
        <Button appearance="primary" onClick={handleSubmit}>Сохранить</Button>
      </Form.Group>
    </Form>
  );
};

export default BrightnessSchedulesFormComponent;
