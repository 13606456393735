const players = {
  ALL: "/v3/players", //?limit=10&offset=0"
  MAP: "/v3/players/map",
  REBOOTS: "/v3/reboot_schedules",
  BRIGHTNESS: "/v3/brightness_schedules",
  TAGS: "/v3/players_tags",
  CAMPAIGNS: "/v3/od/campaigns", // ?state=pending&state=running&player_id=155&exchange=0&limit=-1
};

const device = {
  CATEGORIES: "/v3/devices/categories",
  DEVICE: "/v3/devices",
};

const content = {
  FOLDERS: "/v3/odvertisers", // ?order_by=id.asc&limit=-1",
  WIDGETS: "/v3/applications", // ?order_by=id.asc&limit=-1
  TAGS: "/v3/content/items_tags",
  FILES: "/v3/content/items",
  FILLERS: "/v3/od/fillers",
  UPLOAD_FILES: "/v3/content/files",
};

const campaigns = {
  ALL: "/v3/od/campaigns", // ?limit=10&offset=0
  REPORT_SCHEDULES: "/v3/od/campaigns/report_schedules",
  CAMPAIGN_PREVIEW_PLAYERS: "/v3/od/campaign_preview/players",
};

const playlists = {
  ALL: "/v3/triggers",
  CONFLICTS: "/v3/triggers/conflicts",
};

const analytics = {
  ALL: "/v3/od/analytics", // ?start=2022-11-09&end=2022-12-09
};

const user = {
  LOGIN: "/v3/authenticate",
  ME: "/v3/me",
  FORGOT: "/v3/password/restore",
  RESET: "/v3/password/set",
};

const notifications = {
  ROBOT_NOTIFICATIONS: "/v3/notifications",
  MANUAL_NOTIFICATIONS: "/v3/manual_notifications",
  SYNC_DATA: "/v3/od/players/update", // ?dirty=1
};

const settings = {
  ACCOUNTS: "/v3/users",
  ROLES: "/v3/roles",
  SYSTEM: "/v3/organization",
};

const downloads = {
  RELEASES: "/v3/releases",
  ASSETS: "/v3/assets",
};

export {
  players,
  device,
  content,
  campaigns,
  playlists,
  analytics,
  user,
  notifications,
  settings,
  downloads,
};
