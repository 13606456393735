import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Input,
  InputGroup,
  CheckTree,
  Tag,
} from "rsuite";
import { MdSearch } from "react-icons/md";

import ErrorComponent from "components/ErrorComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { fetchPreviewPlayers } from "redux/slices/playlistSlice";
import { loadingStatus } from "helpers/fetcher";
import { playersHierarchyBuilder } from "helpers/player";

const PreviewPlayersComponent = ({
  selectedPreviewPlayers,
  onChangePreviewPlayers,
  error,
}) => {
  const [filteredPreviewPlayers, setFilteredPreviewPlayers] = useState([]);
  const dispatch = useDispatch();
  const { previewPlayers } = useSelector(state => state.playlist);

  const handleFilterPreviewPlayers = (val) => {
    const filteredVal = previewPlayers.objects.filter(item => item.player.name.toLowerCase().includes(val.toLowerCase()));
    const previewObjects = playersHierarchyBuilder(filteredVal, previewPlayers.objects);    
    setFilteredPreviewPlayers(previewObjects);
  };

  useEffect(() => {
    const previewObjects = playersHierarchyBuilder(previewPlayers.objects);    
    setFilteredPreviewPlayers(previewObjects);
  // eslint-disable-next-line
  }, [previewPlayers]);

  useEffect(() => {
    const queryObject = {
      requirements: JSON.stringify({ orientation: "landscape" }),
      type: "single",
      mode: "triggers",
      order_by: "created_at.desc",
      limit: -1,
    };
    const searchParams = `?${(new URLSearchParams(queryObject)).toString()}`;
    dispatch(fetchPreviewPlayers(searchParams));
  }, [dispatch]);

  console.log(filteredPreviewPlayers)

  return (
    <div className="campaigns-form_preview-players">
      <div className="campaigns-form_preview-players-header">
        <h5>Выберите устройства</h5>
      </div>

      <InputFieldComponent label="">
        <InputGroup>
          <InputGroup.Addon>
            <MdSearch />
          </InputGroup.Addon>
          <Input onChange={handleFilterPreviewPlayers} />
          <InputGroup.Button>
            <MdSearch />
          </InputGroup.Button>
        </InputGroup>
      </InputFieldComponent>

      {error ? <ErrorComponent message="Выберите устройства" /> : ""}

      <div className="campaigns-form_content-items-available">
        {previewPlayers.status === loadingStatus.LOADING && "Загрузка..."}
        {filteredPreviewPlayers.length === 0 && "Пусто"}
        <CheckTreeComponent
          items={filteredPreviewPlayers}
          onChange={onChangePreviewPlayers}
          defaultValue={selectedPreviewPlayers}
        />
      </div>

    </div>
  );
};

const CheckTreeComponent = ({ items, onChange, defaultValue=[] }) => {
  return (
    <CheckTree
      height={320}
      cascade={false}
      defaultValue={defaultValue}
      data={items.map(item => ({
        value: item.id,
        data: item,
        children: item.children?.map(child => ({
          value: child.id,
          data: child,
        })),
      }))}
      renderTreeNode={({ data }) => {
        return (
          <Tag
            key={`${data.id}`}
            className="campaigns-form_content-items-item"
            size="lg"
            color="blue"
          >
            {data.name}
          </Tag>
        );
      }}
      onChange={values => onChange(values)}
    />
  );
};

export default PreviewPlayersComponent;
