import { Message, Avatar, Tag } from "rsuite";

import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import FileItemComponent from "components/files/FileItemComponent";
import HeaderComponent from "components/HeaderComponent";
import { loadingStatus } from "helpers/fetcher";
import { convertBytes, getThumbnail } from "helpers/utils";

const FilesListComponent = ({
  items,
  folderItem,
  status,
  error,
  onClick,
  onEdit,
  onRemove,
}) => {

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  return (
    <>
      {folderItem && <HeaderComponent title={`Файлы рекламодателя "${folderItem.name}"`} tag="h6" className="mb-10 ml-10" />}
      {status === loadingStatus.SUCCEEDED && items.length === 0 ? (
        <Message type="warning">Файлов нет</Message>
      ) : (
        <div className="item-list">
          {status !== loadingStatus.SUCCEEDED && <LoaderComponent center={false} />}
          {items.map(contentItem => {
            const file = contentItem.content_files[0].content_file;
            const duration = `${contentItem.data.default_duration || file.data.duration} сек`;
            return (
              <FileItemComponent
                key={`file-${contentItem.id}`}
                id={contentItem.id}
                icon={<Avatar src={getThumbnail(contentItem)} size="lg" />}
                title={contentItem.name}
                subTitle={<><Tag>{convertBytes(file.size).mb}</Tag> <Tag>{duration}</Tag></>}
                onClick={() => onClick(contentItem)}
                onEdit={() => onEdit(contentItem)}
                onRemove={() => onRemove(contentItem.id)}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default FilesListComponent;
