import { useSelector } from "react-redux";

import HeaderComponent from "components/HeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import TotalTableComponent from "components/playlist/PlaylistsFormComponent/TotalTableComponent";
import { loadingStatus } from "helpers/fetcher";
import { formatDate } from "helpers/date";

const PlaylistsInfoComponent = () => {
  const {
    data: playlist, status, error, playlistPlayback, playlistPlayers,
  } = useSelector(state => state.playlist);

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  const startDatetime = formatDate(playlist.revision.data.rrule.dtstart, ".");
  const endDatetime = formatDate(playlist.revision.data.rrule.until, ".");
  // const startDatetime = formatDate(playlist.revision.start_datetime, ".", "+timezone");
  // const endDatetime = formatDate(playlist.revision.end_datetime, ".", "+timezone");

  return (
    <div>
      <HeaderComponent title={playlist.name} tag="h3" gutter={0} />

      <HeaderComponent title="Данные плейлиста" tag="h6" className="inner-header" />
      <TotalTableComponent
        name={playlist.name}
        start={startDatetime.full_datetime}
        end={endDatetime.full_datetime}
        previewPlayersLenght={playlistPlayers?.length}
        filesLenght={playlistPlayback?.length}
        description={playlist.description}
      />
    </div>
  );
};

export default PlaylistsInfoComponent;
