const loginFormInitialData = {
  email: "",
  password: "",
  // секунды
  duration: null,
};

const playersFormInitialData = {
  "name": "",
  "description": "",
  "data.x": 0,
  "data.y": 0,
  "data.width": null,
  "data.height": null,
  "data.orientation": "landscape",
  "tags": [],
  "user_data.address": "Москва",
  "user_data.coords.lat": "",
  "user_data.coords.lng": "",
  "user_data.coords.location": [],
  "user_data.coords.photos": [],
  "user_data.coords.street_view": "",
  "timezone": "Europe/Moscow",
  "type": "single"
};

const rebootsFormInitialData = {
  id: null,
  name: "",
  description: "",
  players: [],
  schedule: [],
};

const brightnessFormInitialData = {
  id: null,
  name: "",
  description: "",
  players: [],
  schedule: [],
};

const campaignsFormInitialData = {
  name: "",
  description: "",
  start: null,
  start_time: null, // null
  end: null,
  end_time: null, // null
  expand: false,
  interval: null,
  layout: null,
  max_interval: null,
  order: 0,
  playback_items: [
    { content_items: [], data: {} }
  ],
  players: [
    { id: 0, skip_days: [] }
  ],
  programmatic_platforms: [],
  type: "normal",
  advertiser: null,
  algorithm: "each_n",
  requirements: {
    orientation: "landscape"
  }
};

const reportsFormInitialData = {
  id: null,
  "name": "",
  "description": "",
  "off_playback_item": null,
  "players": [],
  "schedule": {
    "MON": { "on": null, "off": null },
    "TUE": { "on": null, "off": null },
    "WED": { "on": null, "off": null },
    "THU": { "on": null, "off": null },
    "FRI": { "on": null, "off": null },
    "SAT": { "on": null, "off": null },
    "SUN": { "on": null, "off": null }
  }
};

const playlistsFormInitialData = {
  // type: "schedule" || external,
  name: "",
  description: "",
  requirements: {
    orientation: "landscape"
  },
  data: {
    // rrule: {
    //   dtstart: new Date(),
    //   until: new Date((new Date()).getTime() + 60 * 60 * 1000)
    // },
    // events: [{
    //   type: "url",
    //   url: "http://url.url",
    //   data: "475614dc-951a-45db-97bd-beaa7dc77310"
    // }],
    // events: [{
    //   type: "gpio",
    //   platform: "hummingboard",
    //   pin: 1,
    //   edge: "rising"
    // }]
    // events: [{
    //   type: "serial.[datatype]",
    //   port: "",
    //   speed: "",
    //   command: "",
    // }]
  },
  playback_items: [
    { data: {}, content_items: [] }
  ],
  players: [],
};

const folderFormInitialData = {
  name: "",
  description: "",
  logo: "",
};

const profileFormInitialData = {
  email: "",
  language: "ru",
  "profile.avatar": "",
  "profile.first_name": "",
  "profile.last_name": "",
  "notifications_settings.ad_campaigns_end_1day": "",
  "notifications_settings.ad_campaigns_end_3days": "",
  "notifications_settings.players_clock_drift": "",
  "notifications_settings.players_high_temperature": "",
  "notifications_settings.players_insufficient_free_space": "",
  "notifications_settings.players_no_content_2days": "",
  "notifications_settings.players_no_content_now": "",
  "notifications_settings.players_not_playing": "",
  "notifications_settings.players_not_synchronized": "",
  "notifications_settings.players_offline": "",
  "notifications_settings.players_offline_1day": "",
  "notifications_settings.players_outdated_version": "",
  "notifications_settings.players_playing_with_gaps": "",
  "notifications_settings.players_starts_too_often": "",
  "notifications_settings.used_players_count_overflow": "",
  "notifications_settings.used_players_count_reaching_overflow": "",
};

const accountsFormInitialData = {
  id: null,
  email: "",
  "profile.first_name": "",
  "profile.last_name": "",
  "roles": [],
};

const organizationFormInitialData = {
  "requirements.orientation": "landscape",
  ad_campaign_content_prefetch_days: 3,
  sssp_pin: "",
  audio_filler_behaviour: "continue_previous",

  filler_shuffle: false,
  audio_filler_shuffle: false,
  filler_programmatic: false,
  ad_campaign_perfect_reports: false,
  ad_campaign_auto_synchronize: false,
  ad_campaign_each_n_cluster: false,
  transcode: false,
};

export {
  loginFormInitialData,
  playersFormInitialData,
  rebootsFormInitialData,
  brightnessFormInitialData,
  campaignsFormInitialData,
  reportsFormInitialData,
  playlistsFormInitialData,
  folderFormInitialData,
  profileFormInitialData,
  accountsFormInitialData,
  organizationFormInitialData,
};
