import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, ButtonToolbar, RadioGroup, Radio, Whisper, Popover, Tag } from "rsuite";
import { MdTune, MdSort, MdFilter, MdFilterAlt, MdIosShare, MdMoreVert, MdMore, MdMoreHoriz, MdOutlineMoreHoriz } from "react-icons/md";

import DrawerComponent from "components/DrawerComponent";
import HeaderComponent from "components/HeaderComponent";
import PlayersSideNavComponent from "components/player/PlayersSideNavComponent";
import PlayerFormComponent from "components/player/PlayerFormComponent";
import PlayerDetailComponent from "components/player/PlayerDetailComponent";
import PlayerExportComponent from "components/player/PlayerExportComponent";
import CategoriesTreeComponent from "components/CategoriesTreeComponent";
import CheckTreePickerComponent from "components/CheckTreePickerComponent";

import { fetchPlayers } from "redux/slices/playersSlice";
import { deletePlayer } from "redux/slices/playerSlice";
import { fetchDeviceCategory } from "redux/slices/deviceCategorySlice";
import useLayout from "hooks/useLayout";
import useFilter from "hooks/useFilter";
import usePopup from "hooks/usePopup";
import user from "helpers/user";
import { buildFlatFromDeepObject } from "helpers/utils";
import Mobile from "./Mobile";
import Desktop from "./Desktop";
import { playersFormInitialData } from "constants/form";

const DRAWERS = {
  export: "export",
  categories: "categories",
  filters: "filters-drawer",
};

const contentBarContents = {
  PLAYERS_OVERVIEW: "players_overview",
  PLAYERS_FORM: "players_form",
  PLAYER_DETAIL: "player_detail",
};

const PAGE_FILTERS = {
  all: { label: "Все устройства", value: "" },
  online: { label: "Онлайн устройства", value: "type=single&online=1" },
  offline: { label: "Офлайн устройства", value: "type=single&online=0" },
  not_activated: { label: "Не активированные", value: "type=single&activated=0" },
};

const SORTS = {
  "created_at.asc": { label: "По дате ▲", value: "" },
  "created_at.desc": { label: "По дате ▼", value: "order_by=created_at.desc" },
  "name.asc": { label: "По имени ▲", value: "order_by=name.asc" },
  "name.desc": { label: "По имени ▼", value: "order_by=name.desc" },
};

const Players = () => {
  const [currentContent, setCurrentContent] = useState(contentBarContents.PLAYERS_OVERVIEW);
  const [selectedContentItem, setSelectedContentItem] = useState();
  const [drawerType, setDrawerType] = useState();
  const [reload, setReload] = useState();
  const [extraQueryString, setExtraQueryString] = useState("hide_children=1");

  const navigate  = useNavigate();
  const { "*": relativePath } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.players);
  const { searchState } = useSelector(state => state.layout);
  const {
    filterKey,
    sortKey,
    setFilterKey,
    setSortKey,
    setAdditionalParam,
    queryString,
  } = useFilter({
    filters: PAGE_FILTERS,
    sorts: SORTS,
    externalParams: ["name"],
  });
  const { isDesktop } = useLayout();
  const { responseToaster } = usePopup();

  const SideBar = useCallback(() => (
    <>
      <CheckTreePickerComponent onQueryString={setExtraQueryString} />
      <PlayersSideNavComponent
        handleExportAction={handleExportDrawerOpen}
        handleCategoriesAction={handleCategoriesAction}
      />
    </>
  ), []);

  const handleDetailButton = (id) => {
    setCurrentContent(contentBarContents.PLAYER_DETAIL);
    navigate(`/players/detail/${id}`);
  };

  const handleCreateButton = () => {
    setCurrentContent(contentBarContents.PLAYERS_FORM);
    setSelectedContentItem();
    navigate("/players/form");
  };

  const handleEditButton = item => {
    setCurrentContent(contentBarContents.PLAYERS_FORM);
    const {
      id, name, description, data, tags, user_data, devices,
    } = item;
    const payload = {
      id,
      name,
      description,
      data: { x: data.x, y: data.y, width: data.width, height: data.height },
      tags: tags.map(t => t.id),
      user_data,
      device_category_id: devices[0].device_category_id,
    };
    setSelectedContentItem(buildFlatFromDeepObject(payload));
    navigate(`/players/form/${id}`);

  };

  const handleCloneButton = item => {
    setCurrentContent(contentBarContents.PLAYERS_FORM);
    const {
      id, name, description, data, tags, user_data,
    } = item;
    const payload = {
      ...playersFormInitialData,
      isClone: true,
      id,
      name,
      description,
      data: { x: data.x, y: data.y, width: data.width, height: data.height },
      tags: tags.map(t => t.id),
      user_data,
    };
    setSelectedContentItem(buildFlatFromDeepObject(payload));
    navigate(`/players/clone/${id}`);
  };

  const handleDeleteButton = async id => {
    const res = await deletePlayer(id);
    setReload(prev => !prev);
    responseToaster(res);
  };

  const handleDrawerClose = () => setDrawerType();
  const handleExportDrawerOpen = () => setDrawerType(DRAWERS.export);
  const handleCategoriesAction = () => setDrawerType(DRAWERS.categories);

  const contentComponents = {
    [contentBarContents.PLAYERS_OVERVIEW]: null,
    [contentBarContents.PLAYERS_FORM]: (
      <PlayerFormComponent
        reload={setReload}
        defaultValues={selectedContentItem}
        // onClose={() => setCurrentContent(contentBarContents.PLAYERS_OVERVIEW)}
      />
    ),
    [contentBarContents.PLAYER_DETAIL]: (
      <PlayerDetailComponent
        reload={setReload}
        onEdit={handleEditButton}
        onClone={handleCloneButton}
        onDelete={handleDeleteButton}
        onClose={() => setCurrentContent(contentBarContents.PLAYERS_OVERVIEW)}
      />
    ),
  };

  useEffect(() => setAdditionalParam("name", searchState), [searchState]);

  useEffect(() => {
    const timer = setTimeout(() => {    
      navigate(queryString);
      // const search = new URLSearchParams(queryString);
      // search.set("hide_children", 1);
      // dispatch(fetchPlayers(`?${search.toString()}`));
      const qs = `${queryString}${queryString ? `&${extraQueryString}` : `?${extraQueryString}`}`;
      console.log(qs);
      dispatch(fetchPlayers(qs));
    }, 10);
    return () => clearTimeout(timer);
  }, [queryString, extraQueryString, reload]);

  useEffect(() => {
    if (!relativePath) {
      setCurrentContent(contentBarContents.PLAYERS_OVERVIEW);
    }
  }, [relativePath]);

  useEffect(() => {
    dispatch(fetchDeviceCategory(`?organization_id=${+user.profile.organization_id}`));
  }, []);

  const actions = {
    handleCreateButton,
    handleDetailButton,
    handleEditButton,
    handleCloneButton,
    handleDeleteButton,
    onContentbarClose: () => setCurrentContent(null),
  };

  return (
    <>
      <HeaderComponent
        title={
          <>
            {isDesktop ? "Устройства" : <div>Устройства</div>}
            {" "}
            <Tag size="sm">{PAGE_FILTERS[filterKey].label}</Tag>
          </>
        }
        right={
          <ButtonToolbar>
            <IconButton
              icon={<MdFilterAlt size="1.5em" />}
              appearance="ghost"
              onClick={() => setDrawerType(DRAWERS.filters)}
            />
            <IconButton
              icon={<MdIosShare size="1.5em" />}
              appearance="ghost"
              onClick={handleExportDrawerOpen}
            />
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={
                <Popover arrow={false}>
                  <div>
                    <p>1</p>
                    <p>12</p>
                    <p>123</p>
                  </div>
                </Popover>
              }
            >
              <IconButton icon={<MdMoreHoriz size="1.5em" />} appearance="ghost" />
            </Whisper>
          </ButtonToolbar>
        }
      />
      {isDesktop ? (
        <Desktop
          data={data}
          sideBar={<SideBar />}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      ) : (
        <Mobile
          data={data}
          sideBar={<SideBar />}
          contentBar={contentComponents[currentContent]}
          {...actions}
        />
      )}
      <DrawerComponent
        open={!!drawerType}
        onClose={handleDrawerClose}
        placement={"right"}
      >
        {drawerType === DRAWERS.filters && (
          <>
            <FilterForm value={filterKey} onChange={setFilterKey} />
            <SortForm value={sortKey} onChange={setSortKey} />
          </>
        )}
        {drawerType === DRAWERS.export && <PlayerExportComponent />}
        {drawerType === DRAWERS.categories && <CategoriesTreeComponent />}
      </DrawerComponent>
    </>
  );

};

const FilterForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Фильтры</h6>
    <RadioGroup
      name="filter-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(PAGE_FILTERS).map(f => (
        <Radio key={`filter-${f}`} value={f}>
          {PAGE_FILTERS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);

const SortForm = ({ value, onChange }) => (
  <div className="caps-main">
    <h6 className="mb-20">Сортировка</h6>
    <RadioGroup
      name="sort-list"
      value={value}
      onChange={onChange}
    >
      {Object.keys(SORTS).map(f => (
        <Radio key={`sort-${f}`} value={f}>
          {SORTS[f].label}
        </Radio>
      ))}
    </RadioGroup>
  </div>
);

export default Players;
