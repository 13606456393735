import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Cookies from "universal-cookie";

import { getMe } from "redux/slices/loginSlice";
import useUser from "hooks/useUser";

import Players from "pages/players";
import Reboots from "pages/players/reboots";
import Brightness from "pages/players/brightness";

import Files from "pages/files";

import Playlists from "pages/playlists";

import Campaigns from "pages/campaigns";
import Fillers from "pages/campaigns/fillers";
import Reports from "pages/campaigns/reports";

import Widgets from "pages/widgets";
import Analytics from "pages/analytics";

import Login from "pages/login";
import Forgot from "pages/login/Forgot";
import Reset from "pages/login/Reset";

import Settings from "pages/settings";
import Profile from "pages/settings/profile";
import Password from "pages/settings/password";
import Accounts from "pages/settings/accounts";
import System from "pages/settings/system";
import Downloads from "pages/settings/downloads";

import DrawersComponent from "components/drawers";
import NavbarComponent from "components/NavbarComponent";
import {
  players,
  campaigns,
  contents,
  playlists,
  analytics,
  auth,
  settings,
} from "constants/urls";

const PrivateRoute = () => {
  const { accessToken, setUser } = useUser();

  const cookies = new Cookies();
  const impersonateToken = cookies.get("authToken");

  useEffect(() => {
    const impersonateAuth = async () => {
      if (impersonateToken) {
        cookies.remove("authToken")
        const meResult = await getMe(impersonateToken);
        setUser({
          access_token: impersonateToken,
          profile: meResult,
        });
      }
    };
    impersonateAuth();
  }, []);

  if (impersonateToken) {
    return <Navigate to="/" />
  }

  return (
    accessToken ? (
      <>
        <NavbarComponent />
        <Outlet />
        <DrawersComponent />
      </>
    ) : (
      <Navigate to={auth.LOGIN} />
    )
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<Players />} />
          <Route path={`${players.ALL}/*`} element={<Players />} />
          <Route path={`${players.REBOOTS}/*`} element={<Reboots />} />
          <Route path={`${players.BRIGHTNESS}/*`} element={<Brightness />} />

          <Route path={`${contents.FILES}/*`} element={<Files />} />

          <Route path={`${campaigns.ALL}/*`} element={<Campaigns />} />
          <Route path={`${campaigns.FILLERS}/*`} element={<Fillers />} />
          <Route path={`${campaigns.REPORT_SCHEDULES}/*`} element={<Reports />} />

          <Route path={`${playlists.ALL}/*`} element={<Playlists />} />

          <Route path={`${contents.WIDGETS}/*`} element={<Widgets />} />

          <Route path={`${analytics.ALL}/*`} element={<Analytics />} />

          <Route path={`${settings.ALL}/*`} element={<Settings />} />
          <Route path={`${settings.PROFILE}/*`} element={<Profile />} />
          <Route path={`${settings.PASSWORD}/*`} element={<Password />} />
          <Route path={`${settings.ACCOUNTS}/*`} element={<Accounts />} />
          <Route path={`${settings.SYSTEM}/*`} element={<System />} />
          <Route path={`${settings.DOWNLOADS}/*`} element={<Downloads />} />
        </Route>

        <Route exact path={auth.LOGIN} element={<Login />} />
        <Route exact path={auth.FORGOT} element={<Forgot />} />
        <Route exact path={`${auth.RESET}/*`} element={<Reset />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
