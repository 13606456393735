import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { device } from "constants/endpoints";

const fetchDeviceCategory = createAsyncThunk("deviceCategory", async (query = "") => {
  const response = await fetcher(`${device.CATEGORIES}${query}`);
  return response.json();
});

const createDeviceCategory = async (data) => fetcher(device.CATEGORIES, {
  method: "POST",
  body: JSON.stringify(data),
});
const patchDeviceCategory = async (id, data) => fetcher(`${device.CATEGORIES}/${id}`, {
  method: "PATCH",
  body: JSON.stringify(data),
});
const deleteDeviceCategory = (id) => fetcher(`${device.CATEGORIES}/${id}`, { method: "DELETE" });

const initialState = {
  data: [],
  status: loadingStatus.IDLE,
  error: null,
};

const deviceCategorySlice = createSlice({
  name: "deviceCategory",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDeviceCategory.pending, (state, action) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(fetchDeviceCategory.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchDeviceCategory.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export default deviceCategorySlice.reducer;

// export const { postAdded, postUpdated, reactionAdded } = playerSlice.actions;
export {
  fetchDeviceCategory,
  createDeviceCategory,
  patchDeviceCategory,
  deleteDeviceCategory,
};
