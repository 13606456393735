import { Loader, Placeholder } from 'rsuite';

const LoaderComponent = ({
  size, content, center = true, withPlaceholder = false,
}) => (
  <div>
    <Loader size={size} content={content} center={center} />
    {withPlaceholder && <Placeholder.Paragraph graph="image" />}
  </div>
);

export default LoaderComponent;
