import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  FlexboxGrid,
  Message,
  Badge,
  TagPicker,
} from "rsuite";

import HeaderComponent from "components/HeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import ErrorComponent from "components/ErrorComponent";
import { loadingStatus } from "helpers/fetcher";
import { formatDate } from "helpers/date";

const PlayerInfoComponent = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const { data: player, status, error } = useSelector(state => state.player);
  const { playersTags } = useSelector(state => state.tags);
  const { data: categories } = useSelector(state => state.deviceCategory);

  const tagsChangeHandler = async (val, _) => {
    // await patchPlayer(player.id, { tags: val });
    setSelectedTags(val);
  };

  useEffect(() => {
    setSelectedTags(player?.tags?.map(({ id }) => id) || []);
  }, [player]);

  if (status !== loadingStatus.SUCCEEDED) {
    return <LoaderComponent />;
  }

  if (status === loadingStatus.FAILED) {
    return <ErrorComponent message={error} />;
  }

  const latLng = [
    player.user_data.coords.lat || player.user_data.coords.location?.[0] || 0,
    player.user_data.coords.lng || player.user_data.coords.location?.[1] || 0,
  ].join(",");

  return (
    <div>
      <HeaderComponent
        title={player.name}
        tag="h3"
        gutter={0}
      />

      <strong>
        {categories.find(cat => cat.id === player.devices[0].device_category_id)?.name}
      </strong>

      <HeaderComponent title="Местоположение" tag="h6" className="inner-header" />
      <img
        alt={player.user_data.address}
        className="flex-image"
        src={`https://static-maps.yandex.ru/1.x/?ll=${latLng}&pt=${latLng},pm2lbm&size=600,350&z=14&l=map`}
      />
      <div>{player.user_data.address}</div>

      <HeaderComponent title="Теги" tag="h6" className="inner-header" />
      <TagPicker
        data={playersTags.map(({ id, name }) => ({ label: name, value: id }))}
        value={selectedTags}
        onChange={tagsChangeHandler}
        block
      />
      {(selectedTags.length === 0) && (<Message showIcon type="warning">Ни один тег не привязан</Message>)}
      <br />
  
      {player.devices && (
        <>
          <HeaderComponent title="Данные устройства" tag="h6" className="inner-header" />
          <FlexboxGrid justify="space-between" style={{ lineHeight: 2.2 }}>
            <Item><strong>Статус</strong></Item>
            <Item>{player.devices[0].online ?
              <><Badge color="green" /> Включен</> :
              <><Badge color="red" /> Отключен</>}
            </Item>

            <Item><strong>Координаты</strong></Item>
            <Item>{`x: ${player.data.x} / y: ${player.data.y}`}</Item>

            <Item><strong>Разрешение</strong></Item>
            <Item>{player.devices[0].resolution?.join(" x ")}</Item>

            <Item><strong>Окно</strong></Item>
            <Item>{`${player.data.width} x ${player.data.height}`}</Item>

            <Item><strong>Синхронизация</strong></Item>
            <Item>
              {player.devices[0].synchronized_at ?
                formatDate(player.devices[0].synchronized_at, ".").full_datetime : 
                "никогда"}
            </Item>

            <Item><strong>Часовой пояс</strong></Item>
            <Item>{player.timezone}</Item>
          </FlexboxGrid>
        </>)}
    </div>
  );
};

const Item = ({ children }) => (
  <FlexboxGrid.Item colspan={12}>{children}</FlexboxGrid.Item>
);

export default PlayerInfoComponent;
