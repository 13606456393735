import { playbackPerTime } from "helpers/utils";
import { formatDate } from "helpers/date";

const CampaignsTotalTableComponent = ({
  name,
  start,
  start_time,
  end,
  end_time,
  interval,
  previewPlayersLenght,
  filesLenght,
  duration,
  description,
}) => {
  const perTime = playbackPerTime(interval);
  return (
    <table className="caps-table">
      <tbody>
        <tr><td>Название</td><td>{name}</td></tr>
        <tr>
          <td>Срок действия</td>
          <td>
            <p>с: {formatDate(`${start} ${start_time}`, ".").full_datetime}</p>
            <p>по: {formatDate(`${end} ${end_time}`, ".").full_datetime}</p>
          </td>
          {/* <td>{`${start} ${start_time} - ${end} ${end_time}`}</td> */}
        </tr>
        <tr><td>Частота показов в час</td><td>{interval}</td></tr>
        <tr><td>Блок</td><td>{`${perTime.minutes} или ${perTime.seconds}`}</td></tr>
        <tr><td>Количество устройств</td><td>{previewPlayersLenght}</td></tr>
        <tr><td>Количество файлов</td><td>{filesLenght}</td></tr>
        <tr><td>Общая длительность</td><td>{duration} сек</td></tr>
        <tr><td>Описание</td><td>{description}</td></tr>
      </tbody>
    </table>
  )

};

export default CampaignsTotalTableComponent;
