import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { players, device, playlists } from "constants/endpoints";

const fetchPlayer = createAsyncThunk("players/id", async (id) => {
  const response = await fetcher(`${players.ALL}/${id}`);
  return response.json();
});

const fetchLogs = createAsyncThunk("players/logs", async (id) => {
  const response = await fetcher(`${device.DEVICE}/${id}/logs`);
  return response.json();
});

const fetchScreenshots = createAsyncThunk("players/screenshots", async (id) => {
  const response = await fetcher(`${device.DEVICE}/${id}/screenshots`);
  return response.json();
});

const fetchPlayerTimeline = createAsyncThunk("players/timeline", async ({ id, q = "" }) => {
  const response = await fetcher(`${players.ALL}/${id}/timeline${q}`);
  return response.json();
});

const fetchWindows = createAsyncThunk("players/windows", async (id) => {
  const response = await fetcher(`${players.ALL}/${id}/windows`);
  return response.json();
});

const fetchPlayerCampaigns = createAsyncThunk("players/campaigns", async (query = "") => {
  const response = await fetcher(`${players.CAMPAIGNS}${query}`);
  return response.json();
});

const fetchPlayerPlaylists = createAsyncThunk("players/playlists", async (query = "") => {
  const response = await fetcher(`${playlists.ALL}${query}`);
  return response.json();
});

const createPlayer = async (data) => {
  const response = await fetcher(players.ALL, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response.json();
};
const putPlayer = async (id, data) => {
  const response = await fetcher(`${players.ALL}/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  return response.json();
};
const patchPlayer = async (id, data) => {
  const response = await fetcher(`${players.ALL}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
  return response.json();
};
const deletePlayer = async (id) => await fetcher(`${players.ALL}/${id}`, { method: "DELETE" });

const requestLogs = async id => await fetcher(`${device.DEVICE}/${id}/logs/request`, { method: "POST" });
const requestScreenshots = async id => await fetcher(`${device.DEVICE}/${id}/screenshots/request`, { method: "POST" });
const requestSynchronize = async id => await fetcher(`${device.DEVICE}/${id}/synchronize`, { method: "POST" });
const requestReboot = async id => await fetcher(`${device.DEVICE}/${id}/reboot`, { method: "POST" });
const requestReset = async id => await fetcher(`${device.DEVICE}/${id}/reset`, { method: "POST" });
const requestUnbindCampaigns = async id => await fetcher(`${players.ALL}/${id}/campaigns`, { method: "DELETE" });

const initialState = {
  data: {},
  status: loadingStatus.IDLE,
  error: null,

  logs: [],
  screenshots: [],
  windows: [],
  campaigns: [],
  playlists: [],
  timeline: [],
};

const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlayer.pending, (state, action) => { state.status = loadingStatus.LOADING; })
      .addCase(fetchPlayer.fulfilled, (state, action) => {
        state.status = loadingStatus.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchPlayer.rejected, (state, action) => {
        state.status = loadingStatus.FAILED;
        state.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchLogs.fulfilled, (state, action) => { state.logs = action.payload; })
      .addCase(fetchScreenshots.fulfilled, (state, action) => { state.screenshots = action.payload; })
      .addCase(fetchWindows.fulfilled, (state, action) => { state.windows = action.payload; })
      .addCase(fetchPlayerCampaigns.fulfilled, (state, action) => { state.campaigns = action.payload.objects; })
      .addCase(fetchPlayerPlaylists.fulfilled, (state, action) => { state.playlists = action.payload.objects; })
      .addCase(fetchPlayerTimeline.fulfilled, (state, action) => { state.timeline = action.payload; })
  }
});

export default playerSlice.reducer;

// export const { postAdded, postUpdated, reactionAdded } = playerSlice.actions;
export {
  fetchPlayer,
  fetchLogs,
  fetchScreenshots,
  fetchWindows,
  fetchPlayerCampaigns,
  fetchPlayerPlaylists,
  fetchPlayerTimeline,

  createPlayer,
  patchPlayer,
  putPlayer,
  deletePlayer,
  requestLogs,
  requestScreenshots,
  requestSynchronize,
  requestReboot,
  requestReset,
  requestUnbindCampaigns,
};
