import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  Button,
  DateRangePicker,
  Steps,
  ButtonGroup,
  IconButton,
} from "rsuite";
import PlayIcon from '@rsuite/icons/legacy/Play';

import HeaderComponent from "components/HeaderComponent";
import InputFieldComponent from "components/forms/InputFieldComponent";
import { FormErrorComponent } from "components/ErrorComponent";
import FilesComponent from "./FilesComponent";
import PreviewPlayersComponent from "./PreviewPlayersComponent";
import ConflictsComponent from "./ConflictsComponent";
import TotalTableComponent from "./TotalTableComponent";
import ExternalFieldsComponent from "./ExternalFieldsComponent";
import usePopup from "hooks/usePopup";
import { itemsTotalDuration } from "helpers/utils";
import { formatDate } from "helpers/date";
import { playlistsFormInitialData } from "constants/form";
import {
  fetchPlaylistPlayback,
  fetchPlaylistPlayers,
  createPlaylist,
  putPlaylist,
} from "redux/slices/playlistSlice";

const TITLE = { schedule: "Плейлист", external: "Событие" };

const PlaylistsFormComponent = ({
  type = "schedule",
  reload,
  defaultValues = {},
}) => {
  const formRef = useRef();
  const [formValue, setFormValue] = useState(playlistsFormInitialData);
  const [step, setStep] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedPreviewPlayers, setSelectedPreviewPlayers] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const dispatch = useDispatch();
  const { playlistPlayback, playlistPlayers } = useSelector(state => state.playlist);
  const { responseToaster } = usePopup();
  const totalDuration = useMemo(() => itemsTotalDuration(selectedFiles), [selectedFiles]);

  const validate = () => {
    let step1 = { name: !formValue.name };
    const _e = formValue.data.events?.[0] || {};
    if (_e.type === "url") {
      step1.eventUrl = _e.url.length < 11;
    } else if (_e.type === "gpio") {
      // step1.event = { port: _e.pin.length === 0 };
    } else if (_e.type?.includes("serial")) {
      step1.eventPort = _e.port.length < 3;
      step1.eventCommand = _e.command.length === 0;
    } else {
      step1.dateRange = !formValue.data.rrule.dtstart || !formValue.data.rrule.dtstart;
    }
    const conditions = [
      step1,
      { selectedFiles: selectedFiles.length === 0 },
      { selectedPreviewPlayers: selectedPreviewPlayers.length === 0 },
      { conflicts: null },
    ];

    const errors = {};
    for (const field of Object.keys(conditions[step])) {
      if (conditions[step][field]) errors[field] = true;
      else delete errors[field];
    }

    console.log(errors)

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const cleanForm = () => {
    setFormValue(playlistsFormInitialData);
    setStep(0);
    setFormErrors({});
    setPlaybackItems([]);
    setPlaybackPlayers([]);
  };

  const changeStep = nextStep => {
    console.log(step, nextStep  )
    if (nextStep > step && !validate()) {
      return false;
    }
    setStep(nextStep < 0 ? 0 : (nextStep > 4 ? 4 : nextStep));
  };
  const onNext = () => changeStep(step + 1);
  const onPrevious = () => changeStep(step - 1);

  const handleChangeForm = (values) => {
    setFormValue(values);
  };

  const handleChangeExternalFields = (events) => {
    const values = {
      ...formValue,
      data: { events }
    };
    console.log(values)
    setFormValue(values);
  };

  const handleChangeDateRange = dates => {
    const [start, end] = [new Date(dates[0]), new Date(dates[1])];
    const values = {
      ...formValue,
      data: {
        ...formValue.data,
        rrule: {
          dtstart: `${formatDate(start).full_datetime}:00`,
          until: `${formatDate(end).full_datetime}:00`,
        }
      }
    };
    setFormValue(values);
  };

  const setPlaybackItems = items => {
    setSelectedFiles(items);
    setFormValue({
      ...formValue,
      playback_items: items.map(({ id, data, type }) => ({
        content_items: [{
          content_item_data: type !== "video" ? { duration: data.default_duration} : {},
          id: id,
          playback_item_data: {}
        }],
        data: { orientation: data.orientation },
      })),
    });
  };

  const setPlaybackPlayers = items => {
    setSelectedPreviewPlayers(items);
    setFormValue({
      ...formValue,
      players: items,
    });
  };

  const handleSelectFiles = item => {
    setPlaybackItems([ ...selectedFiles, item ]);
  };

  const handleRemoveFile = index => {
    selectedFiles.splice(index, 1);
    setPlaybackItems([ ...selectedFiles ]);
  };

  const handleSelectedPreviewPlayers = items => {
    setPlaybackPlayers(items);
  };
  
  const handleSubmit = async () => {
    const { id, isClone, ..._formValue } = formValue;
    const payload = { ..._formValue, order: orderItems.map(o => o.id) };

    if (id && !isClone) {
      const res = await putPlaylist(id, payload);
      responseToaster(res);
      if (res.ok) reload(prev => !prev);
      setStep(0);
    } else {
      const res = await createPlaylist(payload);
      responseToaster(res);
      if (res.ok) {
        reload(prev => !prev);
        cleanForm();
      }
    }
  };

  useEffect(() => {
    if (playlistPlayback.length > 0) {
      setPlaybackItems(playlistPlayback.map(it => it.content_items[0].content_item));
    }
  }, [playlistPlayback]);

  useEffect(() => {
    if (playlistPlayers.length > 0) {
      setPlaybackPlayers(playlistPlayers.map(p => p.player.id));
    }
  }, [playlistPlayers]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      validate();
    }
  }, [formValue, selectedFiles, selectedPreviewPlayers]);

  useEffect(() => {
    if (defaultValues.id) {
      setStep(0);
      setFormValue(defaultValues);
      dispatch(fetchPlaylistPlayback({ id: defaultValues.id }));
      dispatch(fetchPlaylistPlayers(defaultValues.id));
    } else {
      cleanForm();

      let data = {};
      if (type === "schedule") {
        const [start, end] = [new Date(), new Date((new Date()).getTime() + 60 * 60 * 1000)];
        data = {
          rrule: {
            dtstart: `${formatDate(start).full_datetime}:00`,
            until: `${formatDate(end).full_datetime}:00`,
          },
        };
      } else {
        data = {
          events: [{ type: "url", url: "", data: "" }],
        };
      }
      setFormValue({ ...formValue, data, type });
    }
  }, [defaultValues.id, defaultValues.isClone, type]);

  return (
    <div>
      <HeaderComponent
        title={`${formValue.id ? (formValue.isClone ? "Клонировать" : "Изменить") : "Создать"} ${TITLE[type]}`}
        tag="h4"
        gutter={0}
      />

      <Steps current={step} small={true}>
        <Steps.Item title="Данные" />
        <Steps.Item title="Файлы" />
        <Steps.Item title="Плеера" />
        <Steps.Item title="+" />
        <Steps.Item title="Ок" />
      </Steps>

      <SteppingButtons
        step={step}
        onPrevious={onPrevious}
        onNext={onNext}
        handleSubmit={handleSubmit}
      />
  
      <Form
        ref={formRef}
        onChange={handleChangeForm}
        formValue={formValue}
        fluid
      >
        {step === 0 && (
          <>
            <InputFieldComponent
              name="name"
              label="Название*"
              errorComponent={formErrors.name && <FormErrorComponent message="Напишите название" />}
            />
            {type === "external" && (
              <ExternalFieldsComponent
                currentData={formValue.data.events?.[0] || {}}
                formErrors={formErrors}
                onChangeEvent={handleChangeExternalFields}
              />
            )}
            <InputFieldComponent name="description" label="Описание" />
            {type === "schedule" && (
              <InputFieldComponent
                label="Период трансляции*"
                errorComponent={formErrors.dateRange && <FormErrorComponent message="Выберите период трансляции" />}
              >
                <DateRangePicker
                  block
                  format="dd.MM.yyyy HH:mm"
                  placeholder="с даты - по дату"
                  onChange={handleChangeDateRange}
                  // value={[
                  //   formValue.data.rrule?.dtstart ? new Date(formValue.data.rrule.dtstart) : new Date(),
                  //   formValue.data.rrule?.until ? new Date(formValue.data.rrule.until) : new Date((new Date()).getTime() + 60 * 60 * 1000),
                  // ]}
                  value={(formValue.data.rrule?.dtstart && formValue.data.rrule?.until) && [new Date(formValue.data.rrule.dtstart), new Date(formValue.data.rrule.until) ]}
                  placement="bottomEnd"
                  cleanable={false}
                />
              </InputFieldComponent>
            )}
          </>
        )}

        {step === 1 && (
          <FilesComponent
            isRootFolder
            selectedFiles={selectedFiles}
            setSelectedFiles={setPlaybackItems}
            onSelectFiles={handleSelectFiles}
            onRemoveFile={handleRemoveFile}
            error={formErrors.selectedFiles}
          />
        )}

        {step === 2 && (
          <PreviewPlayersComponent
            selectedPreviewPlayers={selectedPreviewPlayers}
            onChangePreviewPlayers={handleSelectedPreviewPlayers}
            error={formErrors.selectedPreviewPlayers}
          />
        )}

        {step === 3 && (
          <ConflictsComponent
            formValue={formValue}
            orderItems={orderItems}
            setOrderItems={setOrderItems}
            nextStep={onNext}
          />
        )}

        {step === 4 && (
          <TotalTableComponent
            { ...formValue }
            start={formatDate(formValue.data.rrule.dtstart, ".").full_datetime}
            end={formatDate(formValue.data.rrule.until, ".").full_datetime}
            filesLenght={selectedFiles.length}
            previewPlayersLenght={selectedPreviewPlayers.length}
            duration={totalDuration}
          />
        )}
      </Form>

      <SteppingButtons
        step={step}
        onPrevious={onPrevious}
        onNext={onNext}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

const SteppingButtons = ({
  step,
  onPrevious,
  onNext,
  handleSubmit,
}) => (
  <>
    <hr />
    <ButtonGroup>
      <Button
        appearance="subtle"
        onClick={onPrevious}
        size="sm"
        disabled={step === 0}
      >
        Назад
      </Button>
      {step === 4 ? (
        <Button
          appearance="primary"
          onClick={handleSubmit}
          size="sm"
        >
          Сохранить
        </Button>
      ) : (
        <IconButton
          appearance="link"
          onClick={onNext}
          icon={<PlayIcon />}
          placement="right"
           size="sm"
        >
          Далее
        </IconButton>
      )}
    </ButtonGroup>
    <hr />
  </>
);

export default PlaylistsFormComponent;
